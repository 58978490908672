import _ from 'lodash';
import { createSelector } from 'reselect';
import { UserRole } from '../../application/UserRole';
import {
  locationsAndGroupsSelector,
  getLocationIdsFromTimelineTemplateIds,
  locationsWithArchivedSeparatedSelector,
} from '../../sites/selectors/locationSelectors';
import { ALL_SITES, DEMO_STATUS } from '../constants';

const isNotAdmin = ({ role }) => !(new UserRole(role)).isOrgAdminOrAbove();

export const organizationUsersSelector = state => state.users.entities;
export const organizationUsersLoadedSelector = state => state.users.loaded;

export const nonAdminUsersSelector = createSelector(
  organizationUsersSelector,
  users => _.chain(users).values().filter(isNotAdmin).value());

const getUsersWithLocationIds = (users, locationsAndGroups) => users.map(user => ({
  ...user,
  locationIds: getLocationIdsFromTimelineTemplateIds(
    locationsAndGroups,
    user.timelineTemplateIds
  ),
}));

const addUserToLocationGroup = (usersByLocation, locationId, user) => {
  if (!usersByLocation[locationId]) {
    usersByLocation[locationId] = [];
  }
  usersByLocation[locationId].push(user);
};

export const usersListSelector = state => Object.values(state.users.entities);

export const usersByLocationForUserListSelector = createSelector(
  [
    usersListSelector,
    locationsAndGroupsSelector,
  ],
  (users, locationsAndGroups) => {
    const usersWithLocationIds = getUsersWithLocationIds(users, locationsAndGroups);

    const addUserToAllLocations = (usersByLocation, user) => {
      Object.values(locationsAndGroups.locations).forEach((location) => {
        addUserToLocationGroup(usersByLocation, location.id, user);
      });
    };

    const addUserToSpecificLocations = (usersByLocation, user) => {
      user.locationIds.forEach((locationId) => {
        addUserToLocationGroup(usersByLocation, locationId, user);
      });
    };

    return usersWithLocationIds.reduce((usersByLocation, user) => {
      if (user.locations === ALL_SITES) {
        addUserToAllLocations(usersByLocation, user);
      } else {
        addUserToSpecificLocations(usersByLocation, user);
      }
      return usersByLocation;
    }, {});
  }
);

const sortAlphabeticallyArchivedLast = users => (
  [...(users || [])].sort((a, b) => {
    if (a.archived && !b.archived) return 1;
    if (b.archived && !a.archived) return -1;
    // return a.name.localeCompare(b.name);
    const nameA = a.name || '';
    const nameB = b.name || '';
    return nameA.localeCompare(nameB);
  })
);

export const filteredUsersByLocationSelectorWithArchivedLast = createSelector(
  [
    (state, selectedLocationIds) => selectedLocationIds,
    usersListSelector,
    locationsAndGroupsSelector,
  ],
  (selectedLocationIds, users, locationsAndGroups) => {
    const usersWithLocationIds = getUsersWithLocationIds(users, locationsAndGroups);

    const filteredUsers = usersWithLocationIds.filter((user) => {
      if (user.locations === ALL_SITES) return true;
      if (!selectedLocationIds.length) return true;
      return user.locationIds.some(locationId => selectedLocationIds.includes(locationId));
    });
    return sortAlphabeticallyArchivedLast(filteredUsers);
  }
);

const sortLocationsWithDemoFirst = (a, b) => (
  b.status === DEMO_STATUS) - (a.status === DEMO_STATUS
);

export const userListLocationsSelector = createSelector(
  [
    (state, selectedLocationIds) => selectedLocationIds,
    locationsWithArchivedSeparatedSelector,
    usersByLocationForUserListSelector,
  ],

  (
    selectedLocationIds,
    { notArchivedLocations: locations },
    usersByLocation
  ) => locations.map(location => ({
    id: location.id,
    label: location.name,
    checked: selectedLocationIds.includes(location.id),
    status: location.status,
    secondaryLabel: _.get(usersByLocation, `${location.id}.length`, 0),
  })).sort(sortLocationsWithDemoFirst)
);
