import { TimeslotConstants } from '../constants';
import { RequestActions, RequestConstants } from '../../request';
import { actions as unsavedChangesActions } from '../../unsaved-changes';

const {
  ORGANIZATION_TIMESLOTS_URL: organizationTimeslotsUrl,
  locationTimeslotsUrl,
} = TimeslotConstants;

export const TimeslotActions = {
  fetchDefaultTimeslots(organizationId) {
    return RequestActions.request({
      key: TimeslotConstants.TIMESLOTS_LOADED,
      method: RequestConstants.GET,
      url: organizationTimeslotsUrl(organizationId),
    });
  },
  fetchOrganizationTimeslots(organizationId) {
    return RequestActions.request({
      key: TimeslotConstants.FETCH_ORGANIZATION_TIMESLOTS,
      method: RequestConstants.GET,
      url: organizationTimeslotsUrl(organizationId),
    });
  },
  fetchLocationTimeslots(locationId) {
    return RequestActions.request({
      key: TimeslotConstants.FETCH_LOCATION_TIMESLOTS,
      method: RequestConstants.GET,
      url: locationTimeslotsUrl(locationId),
      content: { locationId },
    });
  },
  updateLocationTimeslots: timeslots => (dispatch) => {
    dispatch(unsavedChangesActions.touched());

    return dispatch({
      type: TimeslotConstants.UPDATE_LOCATION_TIMESLOTS,
      timeslots,
    });
  },
};
